.action-icon {
	margin-left: 3px;
	margin-right: 3px;
	height: 20px;
	width: 20px;
}

.approval-btn-container{
	padding:10px;
	text-align: center;
}
.approval-btn-container > div{
	margin:15px 0
}

.approval-btn-container .btn-approve{
	margin:0 10px
}

.status-dropdown {
  position: relative;
}

.status-dropdown .MuiMenu-paper {
  position: absolute;
  top: 100% !important;
  left: 0 !important;
  transform: translateY(2px) !important;
}