.App {
  text-align: center;
}
::-webkit-scrollbar
{
  width: 0px;

}
::-webkit-scrollbar-thumb{
	background:#111;
	border-radius: 10px;
}
::-webkit-scrollbar:horizontal{
	height: 8px;
	background: #DDD;
}
::-webkit-scrollbar-thumb:horizontal{
	background:#111;
	border-radius: 10px;
}

.card-text.text-secondary{
  width: 100%;
}.card-body{
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5em;
  height: 3em;
  padding: 1rem;
}.card-body:hover{
  cursor: pointer;
}

@media screen and (max-width:660px){
  .card-body{
    height: 5em;
  }
}

.clickable:hover {
	cursor: pointer;
}

/** Page Action Bar **/
.page-actionbar-container {
  display: flex;
  margin: 0 auto;
	width: 95%;
}
.page-actionbar-item {
	display: flex;
	justify-content: flex-start;
	margin-right: 0.3em;
}
.action-btn:hover {
	cursor: pointer;
}

/** Page Filter Bar **/
.page-filter-bar {
	display: flex;
	justify-content: flex-start;
	margin: 20px auto;
	width:95%;
	
}
.page-filter-bar div {
    margin-right: 0.5em;
		max-width: 230px;
}
@media (max-width: 700px) {
	.page-filter-bar {
		flex-direction: column;
		align-items: center;
	}
	.page-filter-bar div {
		display: flex;
		margin-right: 0;
		max-width: 100%;
		margin-bottom: 5px;

}
}

/* Modal container */
.modal-container {
	position: fixed;
	top:50%;
	left:50%;
	width: 65%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	box-shadow: 2px 2px 2px #e6e6e6;
	background-color: #FFF;
	padding:15px;
	overflow:scroll;
	max-height:90vh; 
	display:block;

}

.modal-container::-webkit-scrollbar{
	width:7px;
	background: #DDD;
}
.modal-container::-webkit-scrollbar:horizontal{
	height: 0;
}


/** Basic table style **/

.table-container{
	max-width:95%;
	margin:0 auto;
	margin-top:20px;
}

.basic-table .row{
background-color:rgba(0, 0, 0, 0.04);
}
.basic-table .row:hover{
	background-color:#fff;
}
.basic-table-head{
font-size:.8rem;
border-radius: 30px;
background-color:#111;
}
.basic-table-head th{
  color:#fff;
}
.basic-table-head th:first-child{
  border-top-left-radius: 4px;

}
.basic-table-head th:last-child{
  border-top-right-radius: 4px;

}
.basic-table .cell {
	font-size:0.8rem;
	padding:0;
	text-align: center;
}



/* POPUP DASHBOARD !!!*/
/* ================================= */
@font-face {
	font-family: Maax;
	src: url('/fonts/Maax.otf');
}
.modal-container.login {
	background-image: url('/dash.png');
	background-repeat: no-repeat;
	background-size: cover;
	width: 800px;
	height: 566px;
}

.modal-container.login .content {
	background-color: #FFF1EC;
	font-family: 'Maax';
	width: 80%;
	margin-left: 10%;
	height: 70%;
	margin-top: 15%;
	padding-top: 5%;
}
.modal-container.login .content .title {
	width: 100%;
	font-size: 25pt;
	font-weight: bold;
	text-align: center;
	margin-bottom: 3%;
}
.modal-container.login .content .subtitle {
	width: 100%;
	font-size: 18pt;
	font-style: italic;
	font-weight: bold;
	text-align: center;
	margin-bottom: 3%;
}
.modal-container.login .content .text {
	width: 90%;
	margin-left: 5%;
	text-align: center;
	font-size: 16pt;
}

.modal-container.login .content .button {
	width: 100%;
	text-align:center;
	font-size: 18pt;
	font-weight: bold;
	font-style: italic;
	margin-top: 5%;
	cursor:pointer;	
}
@media only screen and ( max-device-width: 800px) {

	
	.modal-container.login {
		width: 100vw;
		height: revert;
	}
	.modal-container.login .content {
		margin-top: 0px;
		width: 96%;
		margin-left: 2%;
	}
	.modal-container.login .content .title {
		font-size: 18pt;
	}
	.modal-container.login .content .subtitle {
		font-size:13pt;
	}
	.modal-container.login .content .text {
		font-size: 12pt;
	}
	.modal-container.login .content .button {
		font-size: 13pt;
	}
}
/* ================================= */
